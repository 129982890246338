<template>
  <div>
    <the-advisor-layout-header :headline="headline" />

    <v-card flat height="auto" class="d-flex align-stretch mt-6">
      <v-carousel
        v-model="caro"
        light
        height="auto"
        :show-arrows="false"
        hide-delimiters
        hide-delimiter-background
      >
        <v-carousel-item
          key="1"
          reverse-transition="fade-transition"
          transition="fade-transition"
          value="assets"
          class="mb-12"
        >
          <v-row class="mx-12 mt-n8">
            <v-col cols="12" sm="12" md="12" lg="12">
              <advisor-definitions-assets />
            </v-col>
          </v-row>
        </v-carousel-item>

        <v-carousel-item
          key="2"
          reverse-transition="fade-transition"
          transition="fade-transition"
          value="liabilities"
          class="mb-12"
          ><v-row class="mx-12 mt-n8">
            <v-col cols="12" sm="12" md="12" lg="12">
              <advisor-definitions-liabilities />
            </v-col>
          </v-row>
        </v-carousel-item>

        <v-carousel-item
          key="3"
          reverse-transition="fade-transition"
          transition="fade-transition"
          value="income"
          class="mb-12"
          ><v-row class="mx-12 mt-n8">
            <v-col cols="12" sm="12" md="12" lg="12">
              <advisor-definitions-income />
            </v-col>
          </v-row>
        </v-carousel-item>

        <v-carousel-item
          key="4"
          reverse-transition="fade-transition"
          transition="fade-transition"
          value="expenses"
          class="mb-12"
          ><v-row class="mx-12 mt-n8">
            <v-col cols="12" sm="12" md="12" lg="12">
              <advisor-definitions-spending />
            </v-col>
          </v-row>
        </v-carousel-item>

        <v-carousel-item
          key="5"
          reverse-transition="fade-transition"
          transition="fade-transition"
          value="modelportfolios"
          class="mb-12"
          ><v-row class="mx-12 mt-n8"
            ><v-col cols="12" sm="12" md="12" lg="12"
              ><advisor-definitions-model-portfolios />
            </v-col>
          </v-row>
        </v-carousel-item>
      </v-carousel>
    </v-card>
  </div>
</template>

<script>
import store from '@/vuex/store'
import { mapState } from 'vuex'
import { authComputed } from '@/vuex/helpers.js'
import TheAdvisorLayoutHeader from '@/components/TheAdvisorLayoutHeader.vue'
import AdvisorDefinitionsAssets from '@/components/AdvisorDefinitionsAssets.vue'
import AdvisorDefinitionsLiabilities from '@/components/AdvisorDefinitionsLiabilities.vue'
import AdvisorDefinitionsIncome from '@/components/AdvisorDefinitionsIncome.vue'
import AdvisorDefinitionsSpending from '@/components/AdvisorDefinitionsSpending.vue'
import AdvisorDefinitionsModelPortfolios from '@/components/AdvisorDefinitionsModelPortfolios.vue'

export default {
  name: 'advisorDefinitions',

  // Navigation Route Guard to preload API Data before page is being shown
  beforeRouteEnter(routeTo, routeFrom, next) {
    store.dispatch('fetchAdvisorDefinitions').then(() => {
      // making sure, when navigating to this page, the slider allways moves to "DEFINITIONS" ( tab = 1 )
      store.commit('SET_ADVISOR_LAYOUT_TAB', 1)
      store.commit('SET_ADVISOR_DEFINITIONS_CARO', routeTo.params.tabs)
      next()
    })
  },

  components: {
    TheAdvisorLayoutHeader,
    AdvisorDefinitionsAssets,
    AdvisorDefinitionsLiabilities,
    AdvisorDefinitionsIncome,
    AdvisorDefinitionsSpending,
    AdvisorDefinitionsModelPortfolios
  },

  data() {
    return {
      definitions: {
        assets: { id: 1, name: 'Assets' },
        liabilities: { id: 2, name: 'Liabilities' },
        income: { id: 3, name: 'Income' },
        expenses: { id: 4, name: 'Expenses' },
        modelportfolios: { id: 5, name: 'Model-Portfolios' }
      }
    }
  },

  computed: {
    headline() {
      return 'Advisor Definitions / ' + this.page.name
    },

    caro: {
      get() {
        return this.system.caroAdvisorDefinitions
      },
      set(definition) {
        this.$store.commit('SET_ADVISOR_DEFINITIONS_CARO', definition)
        this.$router.push({
          name: 'advisorDefinitions',
          params: { tabs: definition }
        })
      }
    },

    page() {
      let page = this.definitions[this.caro]
      return page
    },

    ...mapState(['system']),

    ...authComputed
  }
}
</script>

<style lang="scss" scoped></style>
